export default class CurrencyFormatter {
  constructor() {
    this.formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    })
  }

  format(value) {
    return this.formatter.format(value)
  }
}
