import * as Rails from "rails-ujs"

import ajaxSetup from "./ajaxSetup"
import airbrake from "./initAirbrake"

import "bootstrap"

airbrake.call(() => {
  Rails.start()
  ajaxSetup()
})
