export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    /* eslint-disable */
    $.ajaxSetup({
      headers: {
        "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
      },
    })
    /* eslint-enable */
  })
}
